<template>
  <div>
    <b-button size="sm" variant="outline-primary" @click="productPage('Add')"
      >Add New</b-button
    >
    <br />
    <br />
    <b-card no-body class="card-company-table" v-if="!isMobile">
      <b-row>
        <b-col md="12">
          <b-card
            v-if="dataSubscription && !isLoading"
            no-body
            class="card-company-table"
          >
            <b-table
              show-empty
              :items="dataSubscription"
              responsive
              :fields="fields"
              class="mb-0"
            >
              <template #cell(amount_total)="data">
                {{ data.item.currency }} {{ formatPrice(data.value) }}
              </template>
              <template #cell(price)="data">
                {{ data.item.currency }} {{ formatPrice(data.value) }}
              </template>
              <template #cell(service_name)="data">
                {{ data.qty }}
                <template v-if="data.qty > 1">{{
                  data.value + " x".data.qty
                }}</template>
                <template v-else>{{ data.value }}</template>
              </template>
              <template #cell(action)="data">
                <b-link @click="productPage('Upgrade')" title="Upgrade">
                  <span>Upgrade </span>
                </b-link>
                <!--|
                <b-link @click="actionButton(data.item)" title="Remove">
                  <span> Terminate</span>
                </b-link-->
              </template>
              <template>
                <span>You have no subscription</span>
              </template>
            </b-table>
          </b-card>
          <template v-if="isLoading">
            <loading></loading>
          </template>
        </b-col>
      </b-row>
    </b-card>

    <subscribe-card-list
      v-if="isMobile"
      :data="dataSubscription"
      :is-loading="isLoading"
    ></subscribe-card-list>

    <div v-if="ntivi_migration == 1"><em>* Notes : your subscriptiom has been convert to Ntivi</em></div>
    <div class="pricing-free-trial">
      <b-row>
        <b-col lg="10" offset-lg="3" class="mx-auto">
          <div class="pricing-trial-content d-flex justify-content-between">
            <div class="text-center text-md-left mt-3">
              <h3 class="text-primary">
                {{ $t("form.page.subscription.Closing Statement") }}
              </h3>
              <h5>{{ $t("form.page.subscription.Sub Closing Statement") }}</h5>
              <br />
              <div>
                <h4>+62 (21) 8082 1777</h4>
                <span class="text-body">{{
                  $t("form.page.subscription.Last Closing Statement")
                }}</span>
              </div>
            </div>

            <!-- images -->
            <b-img
              fluid
              :src="
                require('@/assets/images/illustration/pricing-Illustration.svg')
              "
              class="pricing-trial-img"
              alt="svg img"
            />
            <!--/ images -->
          </div>
        </b-col>
      </b-row>
    </div>
  </div>
</template>

<style>
.b-table-empty-row {
  text-align: center;
}
</style>

<script>
import BCardCode from "@core/components/b-card-code";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import axios from "@axios";
import Loading from "@core/components/loading/Loading.vue";
import { isMobile } from "mobile-device-detect";
import SubscribeCardList from "./SubscribeCardList.vue";
import {
  BFormInput,
  BFormGroup,
  BForm,
  BRow,
  BCol,
  BButton,
  BCardText,
  BFormSelect,
  BFormTextarea,
  BTable,
  BCard,
  BLink,
  BImg,
} from "bootstrap-vue";

export default {
  components: {
    BCardCode,
    ValidationProvider,
    ValidationObserver,
    BCardText,
    BFormInput,
    BFormGroup,
    BForm,
    BRow,
    BCol,
    BButton,
    BFormSelect,
    ToastificationContent,
    BFormTextarea,
    BTable,
    BCard,
    Loading,
    BLink,
    BImg,
    SubscribeCardList,
  },
  data() {
    return {
      dataSubscription: [],
      isLoading: false,
      isMobile: isMobile,
      fields: [
        { key: "service_name", label: "SERVICE NAME" },
        { key: "amount_total", label: "TOTAL" },
        { key: "service_status", label: "SERVICE STATUS" },
        { key: "action", label: "ACTION" },
      ],
      ntivi_migration: 0
    };
  },
  methods: {
    init() {
      this.checkNtivi();
      this.getData();
    },
    actionButton(param) {
      this.$router.push({
        name: "product-ticket",
        params: {
          action: "Terminate",
          product_category_id: "Terminate",
          text: "i want to terminate my  " + param.service_name + " Package",
        },
      });
      // axios.get("profile/data").then((response) => {
      //   var sendto = '6285811371894';
      //   //var sendto = '628998161865';
      //   if(response.data.address.street_address != null){
      //     var address = response.data.address.street_address.value+'\r\n';
      //   }else{
      //     var address = '';
      //   }

      //   if(response.data.address.phone_number != null){
      //     var phone_number = response.data.address.phone_number.value+'\r\n';
      //   }else{
      //     var phone_number = '';
      //   }
      //   if(response.data.address.email != null){
      //     var email = response.data.address.email.value+'\r\n';
      //   }else{
      //     var email = '';
      //   }

      //   var user_name = localStorage.getItem("name");
      //   var customer_code = localStorage.getItem("customer_code");
      //   var end_message = '```this request is generated from customer portal```';
      //   var message = 'Hi, Im \r\n*'+ user_name +'* _('+ customer_code +')_ \r\n'+phone_number+email+address+' \r\ni want to remove my '+param.service_name+' package\r\n'+end_message;
      //   window.open('https://api.whatsapp.com/send?phone='+sendto+'&text='+window.encodeURIComponent(message));
      // });
    },
    formatPrice(value) {
      let val = (value / 1).toFixed(0).replace(".", ",");
      return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
    },
    productPage: function(action) {
      this.$router.push({ name: "products", params: { id: action } });
    },
    getData: function() {
      this.isLoading = true;
      const token = localStorage.getItem("token");
      axios
        .get("subscription/list")
        .then((response) => {
          this.dataSubscription = response.data.data;
          this.$toast({
            component: ToastificationContent,
            props: {
              title: "Ticket",
              icon: "EditIcon",
              variant: "success",
              text: response.data.message,
            },
          });
        })
        .catch((error) => {
          if (error.response.data.message == "Expired token") {
            localStorage.clear();
            this.$router.push({ name: "login" });
            this.$toast({
              component: ToastificationContent,
              props: {
                title: "Subscribe",
                icon: "EditIcon",
                variant: "success",
                text: "Session Expired, Please Login Again",
              },
            });
          } else {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: "Subscribe",
                icon: "EditIcon",
                variant: "success",
                text: error.response.data.message,
              },
            });
          }
        })
        .finally(() => {
          this.isLoading = false;
        });
    },
    checkNtivi: function() {
      axios
        .get("subscription/customer-package-ntivi")
        .then((response) => {
          this.ntivi_migration = response.data.ntivi_migration;
        })
        .catch((error) => {
          
        })
        .finally(() => {
        });
    }
  },
  mounted() {
    this.init();
  },
};
</script>
<style lang="scss">
@import "@core/scss/vue/pages/page-pricing.scss";
</style>
