<template>
  <div>
    <div v-if="isLoading == true">
      <loading></loading>
    </div>
    <template v-if="!isLoading">
      <b-card no-body class="card-company-table" v-for="data in data" :key="data.id">
        <b-card-body>
          <b-row>
            <b-col md="12">
              <h4 style="margin-bottom:10px; margin-left:-10px;"><span class="badge badge-light-primary"> {{ data.service_name }} </span></h4>
              
              <b-row>
                <div class="col-4"><strong>Total</strong></div>
                <div class="col-8">: IDR {{ formatPrice(data.amount_total) }}</div>
              </b-row>
              <b-row>
                <div class="col-12"><strong>Status </strong></div>
                <div class="col-12">{{ data.service_status }}</div>
              </b-row>
              <br/>
              <span style="float: left" class="card-text">
                <b-button size="sm" variant="outline-success" @click="productPage('Upgrade')">Upgrade</b-button>
              </span>
              <span style="float: right" class="card-text">
                <b-button size="sm" variant="outline-warning" @click="actionButton(data)">Terminate</b-button>
              </span>
            </b-col>
          </b-row>
        </b-card-body>
      </b-card>
    </template>
  </div>
</template>

<style>
.b-table-empty-row {
  text-align: center;
}
</style>

<script>
import BCardCode from "@core/components/b-card-code";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import axios from "@axios";
import Loading from "@core/components/loading/Loading.vue";
import { isMobile } from "mobile-device-detect";
import {
  BFormInput,
  BFormGroup,
  BCardBody,
  BForm,
  BRow,
  BCol,
  BButton,
  BCardText,
  BFormSelect,
  BFormTextarea,
  BTable,
  BCard,
  BLink,
  BImg,
  BBadge,
} from "bootstrap-vue";

export default {
  components: {
    BCardCode,
    BCardBody,
    BBadge,
    ValidationProvider,
    ValidationObserver,
    BCardText,
    BFormInput,
    BFormGroup,
    BForm,
    BRow,
    BCol,
    BButton,
    BFormSelect,
    ToastificationContent,
    BFormTextarea,
    BTable,
    BCard,
    Loading,
    BLink,
    BImg,
  },
  props: {
    data: {
      type: Array,
      default: () => [],
    },
    isLoading: {
      type: Boolean,
      default: () => false,
    },
  },
  data() {
    return {
      dataSubscription: [],
    };
  },
  methods: {
    init() {
      //this.getData();
    },
    formatPrice(value) {
      let val = (value / 1).toFixed(0).replace(".", ",");
      return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
    },
    productPage: function (action) {
      this.$router.push({ name: "products", params: { id: action } });
    },
    actionButton(param) {
      this.$router.push({
        name: "product-ticket",
        params: {
          action: "Terminate",
          product_category_id: "Terminate",
          text: "i want to terminate my  " + param.service_name + " Package",
        },
      });
    },
  },
  mounted() {
    this.init();
  },
};
</script>
<style lang="scss">
@import "@core/scss/vue/pages/page-pricing.scss";
</style>
